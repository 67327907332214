<template>
    <div class="home">
        <nav class="nav_sech">
            <el-input class="el_input" v-model="Roleform.name" placeholder="请输入角色名称"></el-input>
            <el-select class="el_input" v-model="Roleform.state" placeholder="请选择状态" clearable>
                <el-option label="禁用" value="0"></el-option>
                <el-option label="开启" value="1"></el-option>
            </el-select>
            <el-input class="el_input" v-model="Roleform.Remarks" placeholder="请输入描述关键字"></el-input>
            <el-button class="el_btn" @click="Roleformenu" type="warning">查询</el-button>
            <el-button class="el_btn" @click="tableDataAdd" type="primary">新增</el-button>
        </nav>
        <div class="inte"></div>
        <main class="el_table" style="margin: 15px 15px 0">
            <el-table
                    :data="tableData"
                    style="width: 100%;margin-bottom: 20px"
                    border>
                <el-table-column
                        prop="name"
                        label="角色名称"
                        align="center"
                        width="270">
                </el-table-column>
                <el-table-column
                        prop="sort"
                        label="排序"
                        align="center"
                        width="170">
                </el-table-column>
                <el-table-column prop="remark" label="角色描述" align="center" width="505">

                </el-table-column>
                <el-table-column  label="状态" align="center" width="170">
                    <template slot-scope="scope">
                        <span v-if="scope.row.state==0">禁用</span>
                        <span v-if="scope.row.state==1">开启</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="500">
                    <template slot-scope="scope">
                        <el-button
                                size="medium "
                                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button
                                size="medium "
                                type="warning"
                                @click="handleProhdist(scope.$index, scope.row)">
                                分配权限
                        </el-button>
                        <el-button
                                size="medium "
                                :type="scope.row.state==0?'primary':'warning'"
                                @click="handleProhibit(scope.$index, scope.row)">
                            {{scope.row.state==0?'开启':'禁用'}}
                        </el-button>
                        <el-button
                                size="medium "
                                type="danger"
                                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height:60px;text-align: center;">
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10,20, 30, 40, 50]"
                        :page-size="10"
                        :current-page="pages"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="connt">
                </el-pagination>
            </div>
        </main>
        <!--新增弹出框-->
        <el-dialog
                title="新增"
                :visible.sync="RoleAdd"
                width="38%">
            <template>
                <el-form ref="permission" size="medium " :model="permission" label-width="80px">
                    <el-form-item label="角色名称">
                        <el-input v-model="permission.name" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="角色描述">
                        <el-input v-model="permission.remark"></el-input>
                    </el-form-item>
                    <el-form-item label="排序索引">
                        <el-input v-model="permission.sort"></el-input>
                    </el-form-item>
                    <span>0-99 排序值越低越靠前</span>
                </el-form>
            </template>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="RoleAdd = false">取 消</el-button>
                    <el-button type="primary" @click="postAddData">确 定</el-button>
                </span>
        </el-dialog>
        <!--编辑弹出框-->
        <el-dialog
                title="编辑"
                :visible.sync="RoleEdit"
                width="38%">
            <template>
                <el-form ref="formEdit" size="medium " :model="formEdit" label-width="80px">
                    <el-form-item label="角色名称">
                        <el-input v-model="formEdit.name" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="角色描述">
                        <el-input v-model="formEdit.remark"></el-input>
                    </el-form-item>
                    <el-form-item label="排序索引">
                        <el-input v-model="formEdit.sort"></el-input>
                    </el-form-item>
                    <span>0-99 排序值越低越靠前</span>
                </el-form>
            </template>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="RoleEdit = false">取 消</el-button>
                    <el-button type="primary" @click="postRoleData">确 定</el-button>
                </span>
        </el-dialog>
		<!--分配弹框-->
        <el-dialog
                title="权限分配"
                :visible.sync="Rolematch"
                width="60%">
            <template>
                <div class="role_box">
                    <div class="role_head">
                       <h3>系统菜单(超级管理员)</h3>
                    </div>
                    <div class="role_metch">
                        <el-tree
                                @check-change="currentChange"
                                node-key="id"
                                :default-checked-keys="checkedKeys"
                                :props="props"
                                :load="loadNode"
                                :data="treeData"
                                lazy
                                show-checkbox>
                            <template slot-scope="{ node, data}">
                                <div>
                                    <span>{{data.name}}</span>
                                    <span v-if="data.type==0">【左侧菜单(url)权限】</span>
                                    <span v-if="data.type==1">【普通页面链接(url)权限】</span>
                                    <span v-if="data.type==2">【页面按钮权限】</span>
                                    <span v-if="data.type==3">【api接口】</span>
                                </div>
                            </template>
                        </el-tree>
                    </div>
                </div>
            </template>
<!--            <span slot="footer" class="dialog-footer">-->
<!--                    <el-button @click="Rolematch = false">取 消</el-button>-->
<!--                    <el-button type="primary" @click="postRolematch">确 定</el-button>-->
<!--            </span>-->
        </el-dialog>

    </div>
</template>

<script>
    export default {
        name: "Role",
        data(){
            return{
                Roleform:{},//搜索
                permission:{
                    state:1,
                    uid:this.Util.getUid()
                },//新增
                formEdit:{},
                tableData:[],

                RoleEdit:false,//控制编辑弹框
                RoleAdd:false,
                Rolematch:false,//分配弹框
                tableIndex:'',//记录操作列表的索引

                pages:1,//页数操作
                limits:10,
                connt:null,

                treeData:[],
                checkedKeys: ['9', '10'],
                props: {
                    label: 'name',
                    children: 'zones',
                    isLeaf: 'leaf'
                },
            }
        },
        created() {
            this.getRolesData(this.Roleform)
        },
        methods:{
            loadNode(node, resolve) {
                this.postRoledata(node,resolve)
            },
            postRoledata:function(node, resolve){
                var _self=this;
                var pid='0';
                if(node.data && node.data.id){
                    pid=node.data.id;
                    _self.renderTree(pid, function(data){
                        resolve(data.perms);
                        _self.checkedKeys = data.checks;
                    })
                }
            },
            //提交分配菜单选择
            currentChange(data,node){
                var _this=this;
                axios({
                    url:this.API.role.authPerm,
                    method: 'post',
                    data:{
                        isselect:node,
                        permid:data.id,
                        roleid:this.chengsid
                    },
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then((res)=>{
                    if(res.data.success){
                    }else{
                        _this.$message.error(res.data.msg)
                    }
                })
            },
            //查询
            Roleformenu:function () {
                this.pages=1;
                this.getRolesData(this.Roleform)
            },
            //新增弹框
            tableDataAdd:function(){
                this.RoleAdd=true;
            },
            getRolesData:function (role) {
                var _self=this;
                axios.get(this.API.role.list,{
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    params:{
                        name:role.name,
                        remark:role.Remarks,
                        state:role.state,
                        page:this.pages,
                        limit:this.limits
                    }
                }).then(function (res) {
                    if(res.data.success){
                        _self.tableData=res.data.data.list;
                         _self.connt= res.data.data.totalRow;
                    }
                })
            },
            postAddData:function () {
                    var _this=this;
                    axios({
                        url:this.API.role.add,
                        method: 'post',
                        data:this.permission,
                        headers:{
                            token:this.Util.getjwtToken(),
                        },
                    }).then(function (res) {
                        if(res.data.success){
                            _this.permission={
                                state:1,
                                uid:_this.Util.getUid()
                            };
                            _this.RoleAdd=false;
                            _this.getRolesData(_this.Roleform);//重新获取列表
                        }
                    })
            },
            // 编辑
            handleEdit:function (s,e) {
               this.RoleEdit=true;
               this.tableIndex=s;
               this.formEdit=JSON.parse(JSON.stringify(e));
            },
            //提交编辑数据
            postRoleData:function(){
                var _this=this;
                axios({
                    url:this.API.role.update,
                    method: 'post',
                    data:this.formEdit,
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then((res)=>{
                    if(res.data.success){
                        _this.$message.success(res.data.msg);
                        _this.$set(_this.tableData,_this.tableIndex,res.data.data);
                        _this.RoleEdit=false;
                    }else {
                        _this.$message.error(res.data.msg);
                    }
                })
            },
            //分配
            handleProhdist:function(s,e){
                this.Rolematch=true;
                this.chengsid=e.id;
                var _self=this;
                _self.renderTree('0', function(data){
                    _self.treeData = data.perms;
                    _self.checkedKeys = data.checks;
                })
            },
            //分配菜单绚烂
            renderTree: function(pid, callback){
                axios.get(this.API.role.rolePerms,{
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    params:{
                        pid:pid,
                        roleid:this.chengsid
                    }
                }).then(function (res) {
                    if(res.data.success){
                        callback(res.data.data);
                    }
                })
            },
            // 禁用或者开启
            handleProhibit:function (s,e) {
                if(e.state==1){
                    e.state=0;
                }else{
                    e.state=1;
                }
                this.formEdit=JSON.parse(JSON.stringify(e));
                this.postRoleData();//调用编辑接口
            },
            //删除
            handleDelete:function(s,e){
                var _self=this;
                this.$confirm('此操作将永久删除该权限, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    _self.postDeleteData(s,e);
                })
            },
            //提交删除数据
            postDeleteData:function(index,listId){
                var _this=this;
                axios({
                    url:this.API.role.delete,
                    method: 'delete',
                    params:{
                        id:listId.id,
                    },
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then((res)=>{
                    if(res.data.success){
                        _this.$message.success('删除成功!');
                        _this.tableData.splice(index, 1);
                    }else{
                       _this.$message.error(res.data.msg)
                    }
                })
            },
            //分页操作
            handleSizeChange:function (e) {
                this.limits=e;
                this.getRolesData(this.Roleform);
            },
            handleCurrentChange:function () {
                this.pages=e;
                this.getRolesData(this.Roleform);
            }

        }
    }
</script>

<style scoped lang="scss">
 
</style>